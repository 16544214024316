<template>
  <Default>
    <div class="usuarios">
      <banner
        :fondo="KanbanBG"
        titulo="Adelantos"
        descripcion="Lista de Adelantos registrados"
      ></banner>

      <section class="main__content">
        <div class="card">
          <div class="card-header row no-gutters">
            <section class="d-md-flex col-12 col-md-3">
              <div class="d-flex align-items-center">
                <span class="small text-muted mr-2">Mostrar</span>

                <select
                  @change="getItems"
                  class="form-control form-control-sm nro-items"
                  v-model="numberPaginate"
                  placeholder="Cantidad a mostrar"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </section>

            <!-- <input
              type="search"
              placeholder="Buscar por ID o nombre"
              class="form-control search col-12 col-md-4 mt-2 mt-md-0"
              v-model="query"
              @change="getItems"
            /> -->
          </div>

          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-custom" >
                <thead class="thead-dark">
                  <tr>
                    <th scope="col">
                      <span class="small">Cód. Ticket</span>
                    </th>
                    <th scope="col">
                      <span class="small">Estado del Adelanto</span>
                    </th>
                    <th scope="col">
                      <span class="small">Contratista</span>
                    </th>
                    <th scope="col">
                      <span class="small">Fecha de Solicitud</span>
                    </th>
                    <th scope="col">
                      <span class="small">Monto Solicitado</span>
                    </th>

                    <th scope="col">
                      <span class="small"></span>
                    </th>
                  </tr>
                </thead>
                <tbody v-if="!loading">
                  <tr v-for="item in items" :key="item.asignacionId">
                    <td data-label="Código Ticket" scope="row">
                      <span class="small">{{
                        item.Asignacion.codigoTicket
                      }}</span>
                    </td>
                    <td data-label="Estado del Adelanto" scope="row">
                      <b-badge
                        class="px-4 py-2"
                        pill
                        :variant="item.estado == 0 ? 'dark' : 'success'"
                        >{{
                          item.estado == 0 ? "Por Revisar" : "Aprobado"
                        }}</b-badge
                      >
                    </td>
                    <td data-label="Contratista" scope="row">
                      <span class="small" v-if="item.contratista"
                        >{{ item.contratista.nombres }}
                        {{ item.contratista.apellidos }}</span
                      >
                    </td>
                    <td data-label="Fecha de Solicitud">
                      <p class="small mt-2 mb-1">
                        {{ item.fecha }}
                      </p>
                    </td>
                    <td data-label="Monto Solicitado">
                      <p class="small mt-2 mb-1">
                        {{ formatoAPrecio(item.monto) }}
                      </p>
                    </td>

                    <td data-label="Acciones" class="text-right">
                      <button
                        type="button"
                        class="btn btn-warning btn-sm mr-2"
                        v-b-tooltip.hover
                        @click="openVerificarAdelanto(item)"
                        title="Verificar Adelanto"
                        v-if="verificarAdelanto(item)"
                      >
                        <i class="fas fa-donate"></i>
                      </button>
                      <!-- VERIFICAR ADELANTO ADELANTO -->
                      <button
                        type="button"
                        class="btn btn-info btn-sm"
                        v-b-tooltip.hover
                        @click="openInfoAdelanto(item)"
                        title="Información del Adelanto"
                        v-if="isAdmin || isFinanzas || isContratista"
                      >
                        <i class="fas fa-info-circle"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr v-for="i in 10" :key="i">
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                  </tr>
                </tbody>
              </table>

              <p
                class="text-muted text-center mt-4"
                v-if="items.length == 0 && !loading"
              >
                No existen adelantos registrados
              </p>
            </div>

            <div class="mt-3 d-flex justify-content-end">
              <div class="overflow-auto">
                <b-pagination
                  v-model="page"
                  :total-rows="nroItems"
                  :per-page="numberPaginate"
                  @change="getPages"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- MODAL PARA VERIFICAR ADELANTO -->
      <modal-verificar-adelanto
        v-if="asignationSelected"
        :active="verificarAdelantoState"
        :item="asignationSelected"
        @on-modal="verificarAdelantoState = $event"
        @on-finished="getItems"
      ></modal-verificar-adelanto>

      <!-- MODAL PARA INFO ADELANTO -->
      <modal-info-adelanto
        v-if="asignationSelected"
        :active="infoAdelantoState"
        :item="asignationSelected"
        @on-modal="infoAdelantoState = $event"
        @on-finished="getItems"
      ></modal-info-adelanto>
    </div>
  </Default>
</template>

<script>
import { mapState } from "vuex";
import Default from "@/layouts/default.vue";
import GetAllAdelantos from "@/apollo/queries/tareas/GetAllAdelantoContratista.gql";
import { precio } from "@/mixins/precio";

import loadPaginate from "@/mixins/loadPaginate.js";

import Banner from "@/components/global/Banner";
import KanbanBG from "@/assets/banner/kanban.jpg";

import ModalVerificarAdelanto from "@/components/asignaciones/modalVerificarAdelantoVista.vue";
import ModalInfoAdelanto from "@/components/asignaciones/modalAdelantoInfo.vue";

export default {
  data() {
    return {
      items: [],
      query: null,
      numberPaginate: 10,
      page: 1,
      nroItems: 1,
      tipoUsuario: 1,
      itemSeleccionado: null,
      loadingRefreshing: false,
      loadingDelete: false,
      loading: false,
      KanbanBG: KanbanBG,

      newState: null,

      asignationSelected: null,

      // ESTADO
      verificarAdelantoState: false,
      infoAdelantoState: false,
    };
  },
  mixins: [loadPaginate, precio],
  created() {
    this.getItems();
  },
  components: {
    Banner,
    Default,
    ModalInfoAdelanto,
    ModalVerificarAdelanto,
  },
  methods: {
    verificarAdelanto(item) {
      return (this.isAdmin || this.isFinanzas) && item.estado == 0;
    },
    getPages(page) {
      this.loading = true;

      this.verificarAdelantoState = false;

      this.asignationSelected = false;

      this.page = page;

      this.loadItems(GetAllAdelantos, this.numberPaginate, page).then(
        (response) => {
          setTimeout(() => {
            this.items = [];

            this.nroItems = response.data.GetAllAdelantoContratista.NroItems;

            this.items = response.data.GetAllAdelantoContratista.data;
            this.loading = false;
          }, 1);
        }
      );

      this.$router.replace({ query: { page } });
    },

    getItems() {
      this.$nextTick(() => {
        this.loading = true;

        this.query = null;
        this.verificarAdelantoState = false;

        this.asignationSelected = false;

        if (this.$route.query.page) {
          const page = this.$route.query.page;
          this.page = page;
        }

        this.loadItems(GetAllAdelantos, this.numberPaginate, this.page).then(
          (response) => {
            this.items = [];

            setTimeout(() => {
              // PARA AFUERA

              this.$nextTick(() => {
                this.nroItems =
                  response.data.GetAllAdelantoContratista.NroItems;

                this.items = response.data.GetAllAdelantoContratista.data;
                this.loading = false;
              });

              this.$nextTick(() => {
                if (this.$route.query.page) {
                  const page = this.$route.query.page;
                  this.page = page;
                }
              });

              // FIN PARA AFUERA
            }, 1);
          }
        );
      });
    },

    openInfoAdelanto(item) {
      this.asignationSelected = { ...item };
      this.infoAdelantoState = true;
    },

    openVerificarAdelanto(item) {
      this.asignationSelected = { ...item };
      this.verificarAdelantoState = true;
    },
  },
  computed: {
    ...mapState(["datosUsuarioLogueado"]),
    itemsFiltrados: function() {
      let data = this.items;

      if (this.query) {
        let queryMinuscula = this.query.toLowerCase();

        data = this.items.filter(
          (item) =>
            item.asignacionId.includes(this.query) ||
            item.contratista.nombres.toLowerCase().includes(queryMinuscula) ||
            item.contratista.apellidos.toLowerCase().includes(queryMinuscula) ||
            item.supervisor.nombres.toLowerCase().includes(queryMinuscula) ||
            item.supervisor.apellidos.toLowerCase().includes(queryMinuscula) ||
            item.local.nomLocal.toLowerCase().includes(queryMinuscula)
        );
      }

      return data;
    },
    isAdmin: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 1;
    },
    isSupervisor: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 2;
    },
    isContratista: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 3;
    },
    isFinanzas: function() {
      return parseInt(this.datosUsuarioLogueado.tipoUsuario) === 4;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-asignacion {
  display: flex;
  justify-content: center;
  align-items: center;
}
.nro-items {
  width: 100%;
}
.card-body {
  padding: 10px;
}
.usuarios {
  &__image {
    width: 40px;
    height: 40px;

    border-radius: 50%;
  }
}
</style>
