var mixin = {
  methods: {
    loadItems(graphQLObject, numberPaginate = 10, page = 1, estado = "") {
      return new Promise((resolveFunction, rejectFunction) => {
        this.$apollo
          .query({
            query: graphQLObject,
            variables: {
              numberPaginate,
              page,
              estado,
            },
            fetchPolicy: "no-cache",
          })
          .then((response) => {
            // return response;
            resolveFunction(response);
          })
          .catch((err) => {
            rejectFunction(err);
          });
      });
    },
  },
};

export default mixin;
